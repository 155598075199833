import React, { Component, SyntheticEvent } from 'react';
import { hot } from 'react-hot-loader/root';
import debounce from '../../utils/debounce';
import focusOnLink from '../../utils/focusOnLink';
import CategorySection from './categorySection/CategorySection';
import CategoryTabs from './categoryTabs/CategoryTabs';

const TOP_OFFSET = 160;
const debounceDelayInMillis = 250;

interface CategoryContainerProps {
  isSignedIn: boolean;
  categoryDataArray: any[];
}

interface CategoryContainerState {
  selectedHeading: string;
  visibilityOffset: number;
}

export function getNewOffset(innerHeight: number, topOffset: number): number {
  return innerHeight > topOffset ? innerHeight - topOffset : 0;
}

class CategoryContainer extends Component<
  CategoryContainerProps,
  CategoryContainerState
> {
  private debounce: ReturnType<typeof debounce>;

  constructor(props: CategoryContainerProps) {
    super(props);
    this.debounce = debounce(this.updateOffset, debounceDelayInMillis);
    this.state = {
      selectedHeading: '',
      visibilityOffset: 0,
    };
  }

  componentDidMount() {
    const { innerHeight } = window;
    this.setState({
      visibilityOffset: getNewOffset(innerHeight, TOP_OFFSET),
    });
    window.addEventListener('resize', this.debounce);
    window.addEventListener('hashchange', this.onHashChange);
    if (window.location.hash) {
      setTimeout(() => focusOnLink(window.location.hash.substr(1)), 500);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.debounce);
    window.removeEventListener('hashchange', this.onHashChange);
  }

  onHeadingVisible = (selectedHeading: string) => {
    this.setState({
      selectedHeading,
    });
  };

  updateOffset = (event: SyntheticEvent) => {
    // @ts-ignore
    const { innerHeight } = event.target;
    this.setState({
      visibilityOffset: getNewOffset(innerHeight, TOP_OFFSET),
    });
  };

  // eslint-disable-next-line class-methods-use-this
  onHashChange = () => {
    const newHash = window.location.hash;
    focusOnLink(newHash.substr(1));
  };

  render() {
    const { isSignedIn, categoryDataArray } = this.props;
    const { selectedHeading, visibilityOffset } = this.state;
    return (
      <>
        <CategoryTabs selectedHeading={selectedHeading} />
        <CategorySection
          categoryDataArray={categoryDataArray}
          isSignedIn={isSignedIn}
          onHeadingVisible={this.onHeadingVisible}
          visibilityOffset={visibilityOffset}
        />
      </>
    );
  }
}

export default hot(CategoryContainer);
