import Anchor from '@sdtech/sd-ui/dist/commonjs/components/Anchor/Anchor';
import React, { FunctionComponent } from 'react';

interface SubCatsProps {
  abbrev: string;
  name: string;
}

interface SubCategoriesProps {
  subCats: SubCatsProps[];
}

const SubCategories: FunctionComponent<SubCategoriesProps> = ({ subCats }) => {
  const SubCatLinks = subCats.map(({ abbrev, name }) => {
    const path = `/browse/journals-and-books?subject=${abbrev}`;
    return (
      <li
        key={abbrev}
        className="text-s u-padding-xs-bottom browse-subject-subsubject"
      >
        <Anchor id={abbrev} href={path}>{name}</Anchor>
      </li>
    );
  });
  return (
    <ul className="no-bullet-points">{SubCatLinks}</ul>
  );
};

export default SubCategories;
