import React, { FunctionComponent } from 'react';

interface CarouselBannerProps {
    assetsBaseUrl: string;
    backgroundPosition: string;
    prefix: string;
    regionLabel: string;
    imgText: string;
    // eslint-disable-next-line react/require-default-props
    className?: string;
}

const CarouselBanner: FunctionComponent<CarouselBannerProps> = ({
  assetsBaseUrl,
  backgroundPosition,
  prefix,
  regionLabel,
  className = '',
  children,
  imgText = '',
}) => {
    const bannerBackground = {
        backgroundImage: `url(${assetsBaseUrl}/images/${prefix}_banner.jpg)`,
        height: 400,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition,
    };

    return (
        <div className={`row ${className}`} style={bannerBackground}>
            <div className="fixed-width-container">
                <div role="region" className="row" aria-label={regionLabel}>
                        <img
                          src={`${assetsBaseUrl}/images/${prefix}_logo.png`}
                          alt={imgText}
                          className="banner-logo"
                        />
                    <div className="banner-caption-box text-s">
                        {children}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CarouselBanner;
