import Anchor from '@sdtech/sd-ui/dist/commonjs/components/Anchor/Anchor';
import classnames from 'classnames';
import React, { FunctionComponent } from 'react';
import StickyNav from 'react-sticky-el/es/sticky';

interface CategoryAnchorProps {
  categoryName: string;
  selectedHeading: string;
  className: string;
  firstSubCategory: string;
}

const CategoryAnchor: FunctionComponent<CategoryAnchorProps> = ({
  categoryName,
  firstSubCategory,
  selectedHeading,
  children,
  className,
}) => {
  const shouldUnderline = categoryName === selectedHeading;

  const anchorClasses = classnames(
    {
      'tab-elements u-clr-grey8 browse-quicklinks-item text-m': true,
      'tabs-selected': shouldUnderline,
    },
    className,
  );

  return (
    <Anchor hasInheritColor href={`#${firstSubCategory}`} className={anchorClasses}>
      {children}
    </Anchor>
  );
};

interface CategoryTabsProps {
  selectedHeading: string;
}

const CategoryTabs: FunctionComponent<CategoryTabsProps> = ({
  selectedHeading,
}) => (
  <div role="navigation" aria-label="Category">
      <div className="row">
        <h1
          id="main"
          className="col-xs-24 u-padding-l-top u-padding-s-bottom u-padding-xs-hor-from-xs u-padding-0-hor-from-md u-text-left u-h2"
        >
          Explore scientific, technical, and medical research on ScienceDirect
        </h1>
      </div>
      <StickyNav
        stickyClassName="category-tabs-sticky"
        stickyStyle={{ zIndex: '1', position: 'relative' }}
        boundaryElement="#category-sections-desktop"
        hideOnBoundaryHit={false}
      >
        <div className="row u-show-from-md u-bg-white">
          <div className="tabs-underline" />
          <CategoryAnchor
            className="u-margin-l-right"
            firstSubCategory="chemical-engineering"
            selectedHeading={selectedHeading}
            categoryName="physical-sciences-and-engineering"
          >
            Physical Sciences and Engineering
          </CategoryAnchor>
          <CategoryAnchor
            className="u-margin-l-right"
            firstSubCategory="agricultural-and-biological-sciences"
            selectedHeading={selectedHeading}
            categoryName="life-sciences"
          >
            Life Sciences
          </CategoryAnchor>
          <CategoryAnchor
            className="u-margin-l-right"
            firstSubCategory="medicine-and-dentistry"
            selectedHeading={selectedHeading}
            categoryName="health-sciences"
          >
            Health Sciences
          </CategoryAnchor>
          <CategoryAnchor
            className="u-margin-0-right"
            firstSubCategory="arts-and-humanities"
            selectedHeading={selectedHeading}
            categoryName="social-sciences-and-humanities"
          >
            Social Sciences and Humanities
          </CategoryAnchor>
        </div>
      </StickyNav>
  </div>
);

export default CategoryTabs;
