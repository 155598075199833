import LinkButton from '@sdtech/sd-ui/dist/commonjs/components/LinkButton/LinkButton';
import React, { FunctionComponent } from 'react';
import CarouselBanner from '../carouselBanner/CarouselBanner';

interface ResearchConfidenceBannerProps {
  assetsBaseUrl: string;
}

const ResearchConfidenceBanner: FunctionComponent<ResearchConfidenceBannerProps> = ({
  assetsBaseUrl,
}) => (
        <CarouselBanner
          className="research-banner"
          assetsBaseUrl={assetsBaseUrl}
          backgroundPosition="0 0"
          prefix="confidence_in_research"
          regionLabel="Confidence in research"
          imgText="Stories behind the facts, Confidence in research"
        >
            <p>
                See personal stories from the global research community on misinformation,
                public exposure, online abuse and other key issues.
            </p>
            <LinkButton
              className="link-button-tertiary banner-button move-left u-margin-m-top-from-sm u-margin-s-top-from-xs"
              href="https://confidenceinresearch.elsevier.com/"
              target="_blank"
              isExternalLink
              size="small"
            >
                Explore stories
            </LinkButton>
        </CarouselBanner>
    );

export default ResearchConfidenceBanner;
