import React, { FunctionComponent } from 'react';
import CategorySectionDesktop from './CategorySectionDesktop';
import CategorySectionsMobile from './CategorySectionsMobile';

interface CategorySectionProps {
  categoryDataArray: any[];
  isSignedIn: boolean;
  onHeadingVisible: any; // TODO revisit
  visibilityOffset: number;
}

const CategorySection: FunctionComponent<CategorySectionProps> = ({
  categoryDataArray,
  isSignedIn,
  onHeadingVisible,
  visibilityOffset,
}) => (
  <>
    <div className="u-show-from-md" id="category-sections-desktop">
      {categoryDataArray.map(category => {
        const {
          catInfo: { abbrev },
        } = category;
        return (
          <CategorySectionDesktop
            isSignedIn={isSignedIn}
            category={category}
            key={abbrev}
            onHeadingVisible={onHeadingVisible}
            visibilityOffset={visibilityOffset}
          />
        );
      })}
    </div>
    <div className="u-hide-from-md u-padding-xs-hor">
      <CategorySectionsMobile
        isSignedIn={isSignedIn}
        dataArray={categoryDataArray}
      />
    </div>
  </>
);

export default CategorySection;
