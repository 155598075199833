import LinkButton from '@sdtech/sd-ui/dist/commonjs/components/LinkButton/LinkButton';
import React, { FunctionComponent } from 'react';
import CarouselBanner from '../carouselBanner/CarouselBanner';

interface Lancet200BannerProps {
    assetsBaseUrl: string;
}

const Lancet200Banner: FunctionComponent<Lancet200BannerProps> = ({
  assetsBaseUrl,
}) => (
        <CarouselBanner
          className="lancet-banner"
          assetsBaseUrl={assetsBaseUrl}
          backgroundPosition="0"
          prefix="lancet_200"
          regionLabel="The Lancet 200"
          imgText="More than a Medical Journal"
        >
            <p>
                2023 marked 200 years since Thomas Wakley founded The Lancet with the vision
                that it should be more than a medical journal. Over the past two centuries
                the journal has remained committed to achieving its founding mission to
                drive social and political change through advancing medical research and
                science for the greater good.
            </p>
            <LinkButton
              className="link-button-primary banner-button u-margin-s-top"
              href="https://www.thelancet.com/lancet-200?dgcid=social_lancet200_sciencedirect"
              target="_blank"
              isExternalLink
              onDarkBackground
              size="small"
            >
                The Lancet 200
            </LinkButton>
        </CarouselBanner>
    );

export default Lancet200Banner;
