import Accordion from '@sdtech/sd-ui/dist/commonjs/components/Accordion/Accordion';
import AccordionPanel from '@sdtech/sd-ui/dist/commonjs/components/Accordion/AccordionPanel';
import Anchor from '@sdtech/sd-ui/dist/commonjs/components/Anchor/Anchor';
import ButtonAlternative from '@sdtech/sd-ui/dist/commonjs/components/ButtonAlternative/ButtonAlternative';
import NavigateRight from '@sdtech/sd-ui/dist/commonjs/components/Icon/NavigateRight';
import React, { FunctionComponent } from 'react';
import CategoryItems from './categoryItems/CategoryItems';
import SubCategories from './subCategories/SubCategories';

interface CategoryProps {
  catInfo: {
    abbrev: string;
    conversionDriver: string;
    subCats: any[];
    label: string;
    desc: string;
  };
  popData?: any[];
  lpsData?: any[];
}

interface CategorySectionsMobileProps {
  isSignedIn: boolean;
  dataArray: CategoryProps[];
}

const CategorySectionsMobile: FunctionComponent<CategorySectionsMobileProps> = ({
  isSignedIn,
  dataArray,
}) => (
  <div className="browse-subject-mobile">
    <Accordion multiple scrollDelay={300} shouldScroll={false}>
      {dataArray.map(category => {
        const { catInfo, popData = [], lpsData = [] } = category;
        const { abbrev, conversionDriver, subCats, label, desc } = catInfo;
        const path = `/browse/journals-and-books?subject=${abbrev}`;
        return (
          <AccordionPanel key={abbrev} selected={false} title={label}>
            <div className="u-clr-grey8 u-padding-xs-bottom u-padding-xs-top">
              Browse journals and books in:
            </div>
            <SubCategories subCats={subCats} />
            <Anchor
              href={path}
              id={`${abbrev}-link`}
              aria-label={`${label} journals and books`}
            >
              Browse all titles in {label}
            </Anchor>
            <p className="u-padding-s-top">{desc}</p>
            {popData.length > 0 && (
                <CategoryItems responseData={popData} label="Popular Articles" />
            )}
            {lpsData.length > 0 && (
              <CategoryItems
                responseData={lpsData}
                label="Recent Publications"
              />
            )}
            <div className="row u-padding-s-top">
              {isSignedIn ? (
                <ButtonAlternative
                  className="button-alternative-primary recommendations-cta"
                  iconLeft={NavigateRight}
                  href="user/recommendations"
                  type="button"
                >
                  Visit your personalized recommendations page
                </ButtonAlternative>
              ) : (
                <ButtonAlternative
                  className="button-alternative-primary registration-cta"
                  iconLeft={NavigateRight}
                  href="/user/register"
                  type="button"
                  data-aa-conversiondriver={conversionDriver}
                >
                  Register now to receive recommended articles based on your
                  activity
                </ButtonAlternative>
              )}
            </div>
          </AccordionPanel>
        );
      })}
    </Accordion>
  </div>
);

export default CategorySectionsMobile;
