import LinkButton from '@sdtech/sd-ui/dist/commonjs/components/LinkButton/LinkButton';
import React, { FunctionComponent } from 'react';
import CarouselBanner from '../carouselBanner/CarouselBanner';

interface ClimateBannerProps {
  assetsBaseUrl: string;
}

const ClimateBanner: FunctionComponent<ClimateBannerProps> = ({
  assetsBaseUrl,
}) => (
      <CarouselBanner
        className="net-zero-banner"
        assetsBaseUrl={assetsBaseUrl}
        backgroundPosition="0"
        prefix="net_zero"
        regionLabel="Net Zero"
        imgText="Net Zero"
      >
        <p className="u-margin-s-bottom-from-sm">
          Elsevier journals offer the latest peer-reviewed research papers
          on climate change, biodiversity, renewable energy and other topics
          addressing our planet’s climate emergency.
        </p>
        <p>
          Join us in working towards a sustainable future with our
          editorially independent report on creating a Net Zero future.
        </p>
        <LinkButton
          className="link-button-primary banner-button u-margin-m-top-from-sm u-margin-s-top-from-xs"
          href="https://www.elsevier.com/connect/net-zero-report"
          target="_blank"
          isExternalLink
          onDarkBackground
          size="small"
        >
          Get the Net Zero report
        </LinkButton>
      </CarouselBanner>
  );

export default ClimateBanner;
