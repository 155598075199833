import Anchor from '@sdtech/sd-ui/dist/commonjs/components/Anchor/Anchor';
import ButtonAlternative from '@sdtech/sd-ui/dist/commonjs/components/ButtonAlternative/ButtonAlternative';
import NavigateRight from '@sdtech/sd-ui/dist/commonjs/components/Icon/NavigateRight';
import React, { FunctionComponent } from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import CategoryItems from './categoryItems/CategoryItems';
import SubCategories from './subCategories/SubCategories';

interface CategorySectionDesktopProps {
  isSignedIn: boolean;
  category: {
    catInfo: {
      abbrev: string;
      conversionDriver: string;
      subCats: any[];
      label: string;
      desc: string;
    };
    popData?: any[];
    lpsData?: any[];
  };
  onHeadingVisible?: any; // TODO change this
  visibilityOffset: number;
}

const CategorySectionDesktop: FunctionComponent<CategorySectionDesktopProps> = ({
  isSignedIn,
  category: { catInfo, popData = [], lpsData = [] },
  onHeadingVisible = () => {},
  visibilityOffset,
}) => {
  const { abbrev, conversionDriver, subCats, label, desc } = catInfo;
  const path = `/browse/journals-and-books?subject=${abbrev}`;

  const onChange = (isVisible: boolean) => {
    if (isVisible) {
      onHeadingVisible(abbrev);
    }
  };

  /*
    The header's tab should not be highlighted until the header is near the
    top of the window. Therefore, the visibility sensor should not sense the
    header when it lies within the visibilityOffset (the number of pixels
    between TOP_OFFSET from the top of the window, to the bottom of the window).
    I.e. the header is only 'visible' when it is somewhere in the TOP_OFFSET pixels.

    Setting offset={{bottom: visibilityOffset}} is like holding a visibilityOffset
    high piece of paper in front of the screen, TOP_OFFSET pixels away from the top
    of the screen, so you can only see what's in TOP_OFFSET.
  */

  return (
    <div id={abbrev}>
      <div
        className="hor-line u-padding-xxl-ver browse-subject"
        role="region"
        aria-label={label}
      >
        <div className="row">
          <div className="col-md-8 u-padding-s-right">
            <VisibilitySensor
              onChange={onChange}
              delayedCall
              offset={{
                bottom: visibilityOffset,
              }}
            >
              <div className="row">
                <h2 className="u-h3">
                  {label}
                </h2>
              </div>
            </VisibilitySensor>
            <div className="u-padding-s-ver-from-md ">
              <div className="u-clr-grey8 u-padding-xs-bottom u-padding-xs-top">
                Browse journals and books in:
              </div>
              <SubCategories subCats={subCats} />
              <Anchor
                href={path}
                id={`${abbrev}-link`}
                aria-label={`${label} journals and books`}
              >
                Browse all titles in {label}
              </Anchor>
            </div>
          </div>
          <div className="col-md-16">
            <div className="row u-clr-grey8 u-padding-s-bottom u-padding-xs-top">
              <p className="category-summary">{desc}</p>
            </div>
            <div className="row">
              {popData.length > 0 && (
                <div className="col-lg-12 browse-subject-popular">
                  {' '}
                  <CategoryItems
                    responseData={popData}
                    label="Popular Articles"
                  />
                </div>
              )}
              {lpsData.length > 0 && (
                <div className="col-lg-12 browse-subject-latest">
                  {' '}
                  <CategoryItems
                    responseData={lpsData}
                    label="Recent Publications"
                  />
                </div>
              )}
            </div>
            <div className="row u-padding-l-top">
              {isSignedIn ? (
                <ButtonAlternative
                  className="button-alternative-primary recommendations-cta"
                  iconLeft={NavigateRight}
                  href="user/recommendations"
                  type="button"
                >
                  Visit your personalized recommendations page
                </ButtonAlternative>
              ) : (
                <ButtonAlternative
                  className="button-alternative-primary registration-cta"
                  iconLeft={NavigateRight}
                  href="/user/register"
                  type="button"
                  data-aa-conversiondriver={conversionDriver}
                >
                  Register now to receive recommended articles based on your
                  activity
                </ButtonAlternative>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

CategorySectionDesktop.defaultProps = {
  onHeadingVisible: undefined,
};

export default CategorySectionDesktop;
