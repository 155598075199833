export default function focusOnLink(name: string) {
  const linkName = `${name}-link`;
  let namedElements: // eslint-disable-next-line no-undef, @typescript-eslint/array-type
  NodeListOf<HTMLElement> | Array<HTMLElement> = document.getElementsByName(
    linkName,
  );

  if (!namedElements.length) {
    const element = document.getElementById(linkName);
    if (element) {
      namedElements = [element];
    }
  }

  if (namedElements.length) {
    const element = namedElements[0];
    element.focus();
  }
}
