import Anchor from '@sdtech/sd-ui/dist/commonjs/components/Anchor/Anchor';
import HomepageSearchBox from '@sdtech/search-common-components/dist/ui/homepage-search-box/HomepageSearchBox';
import React, { FunctionComponent } from 'react';

const SearchBar: FunctionComponent = () => (
  <div className="fixed-width-container u-padding-s-ver quicksearch">
    <h2 className="u-padding-xs-ver u-h4">
      Search for peer-reviewed journal articles and book chapters (including{' '}
      <Anchor href="#open-access">open access</Anchor> content)
    </h2>
    <HomepageSearchBox
      baseUrl="/search"
      autoSuggestBaseUrl="/search"
      origin="home"
      zone="qSearch"
    />
  </div>
);

export default SearchBar;
