// copy-paste from scc

import { SyntheticEvent } from 'react';

type FunctionType = (e: SyntheticEvent) => void;

function debounce(fn: FunctionType, delay: number) {
  let t: ReturnType<typeof setTimeout>;

  return function debouncedFn(this: any, ...args: any[]) {
    clearTimeout(t);
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const ctx = this;

    t = setTimeout(() => {
      // @ts-ignore
      fn.apply(ctx, args);
    }, delay);
  };
}

export default debounce;
