import { ErrorBoundary } from '@sdtech/core-features-common';
import Anchor from '@sdtech/sd-ui/dist/commonjs/components/Anchor/Anchor';
import classnames from 'classnames';
import React, { FunctionComponent } from 'react';
import Truncate from '../../../common/truncate/Truncate';

interface ResponseProps {
  title: string;
  subtitle: string;
  link: string;
  doi: string;
}

interface CategoryItemsProps {
  responseData: ResponseProps[];
  label: string;
}

const CategoryItems: FunctionComponent<CategoryItemsProps> = ({
  responseData,
  label,
}) => {
  const titleClass = classnames({
    'u-display-inline-block': true,
    'u-font-serif': label === 'Popular Articles' || label === 'Recent Publications',
  });

  const itemList = responseData.map(({
                                       title,
                                       subtitle,
                                       link,
                                       doi,
                                     }) =>
    (
      <li
        data-doi={doi}
        className="text-category-items u-padding-xs-ver citation"
        key={link}
      >
        <Anchor href={link} className={titleClass}>
          <Truncate lines={4} dangerouslySetInnerHTML={{__html: title}} />
        </Anchor>
        <p className="u-clr-grey8 u-font-sans">{subtitle}</p>
      </li>
    ),
  );

  return (
    <ErrorBoundary>
      <h3 className="u-padding-s-ver u-h4">{label}</h3>
      <ul className="u-padding-xxl-right">{itemList}</ul>
    </ErrorBoundary>
  );
};

export default CategoryItems;
