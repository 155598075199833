// @ts-nocheck
/* eslint-disable */
export default {
  rhfConfig: _hpx_.rhfConfig,
  isSignedIn: _hpx_.isSignedIn,
  assetsBaseUrl: _hpx_.assetsBaseUrl,
  supportHubUrls: _hpx_.supportHubUrls,
  categoryDataArray: _hpx_.categoryDataArray,
  oneTrustCookie: _hpx_.oneTrustCookie,
};
