import Carousel from '@sdtech/sd-ui/dist/commonjs/components/Carousel/Carousel';
import React, { FunctionComponent } from 'react';
import ClimateBanner from '../banners/climateBanner/ClimateBanner';
import Lancet200Banner from '../banners/lancet200/Lancet200Banner';
import ResearchConfidenceBanner from '../banners/researchConfidenceBanner/ResearchConfidenceBanner';

interface HomepageCarouselProps {
    assetsBaseUrl: string;
}

const HomepageCarousel: FunctionComponent<HomepageCarouselProps> = ({
    assetsBaseUrl,
}) => (
        <Carousel
          id="bannerCarouselContent"
          className="carousel"
          content={[
              // eslint-disable-next-line react/jsx-key
              <ResearchConfidenceBanner assetsBaseUrl={assetsBaseUrl} />,
              // eslint-disable-next-line react/jsx-key
              <ClimateBanner assetsBaseUrl={assetsBaseUrl} />,
              // eslint-disable-next-line react/jsx-key
              <Lancet200Banner assetsBaseUrl={assetsBaseUrl} />,
          ]}
          indicators={[
              'Confidence in research',
              'Climate change',
              'The Lancet 200',
          ]}
        />
    );

export default HomepageCarousel;
